import { useMemoizedContentGetter } from 'utils/contentful'
import { FlatCarousel } from 'components/Carousels/FlatCarousel'
import { object, array } from 'yup'

let contentSchema = object({
  carousel: object({
    carouselItemsCollection: object({ items: array() }),
  }),
})

function TestimonialCarousel({ content }) {
  let memoizedContent = useMemoizedContentGetter(content, ['carousel'])

  if (!contentSchema.isValidSync(memoizedContent)) {
    console.warn('TestimonialCarousel - INVALID CONTENT RESPONSE', JSON.stringify(memoizedContent))
    return null
  }
  const {
    carousel: { carouselItemsCollection },
  } = memoizedContent

  return <FlatCarousel carouselItems={carouselItemsCollection?.items} idPrefix="home" />
}
export { TestimonialCarousel }
export default TestimonialCarousel
